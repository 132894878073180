@font-face {
    font-family: 'Grotesque Regular';
    src: local('Grotesque Regular'),
        url('/fonts/basis-grotesque-regular-030520-subset.woff2') format('woff2'),
        url('/fonts/basis-grotesque-regular-030520-subset.woff') format('woff'),
        url('/fonts/basis-grotesque-regular-030520-subset.eot') format('eot'),
        url('/fonts/basis-grotesque-regular-030520-subset.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grotesque Medium';
    src: local('Grotesque Medium'),
        url('/fonts/basis-grotesque-medium-030520-subset.woff2') format('woff2'),
        url('/fonts/basis-grotesque-medium-030520-subset.woff') format('woff'),
        url('/fonts/basis-grotesque-medium-030520-subset.eot') format('eot'),
        url('/fonts/basis-grotesque-medium-030520-subset.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grotesque Bold';
    src: local('Grotesque Bold'),
        url('/fonts/basis-grotesque-bold-030520-subset.woff2') format('woff2'),
        url('/fonts/basis-grotesque-bold-030520-subset.woff') format('woff'),
        url('/fonts/basis-grotesque-bold-030520-subset.eot') format('eot'),
        url('/fonts/basis-grotesque-bold-030520-subset.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    padding: env(safe-area-inset);
    background-color: #161616; /* DarkGrey2 */
}

*::selection {
    background: rgba(219, 50, 54, 0.3); /* RedTransparent2 */
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Grotesque Regular', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

#overlay-root {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    cursor: pointer;
    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

button:focus,
button:active,
input:focus,
input:active,
textarea:focus,
textarea:active {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Grotesque Medium', Helvetica, Arial, sans-serif;
}

strong {
    font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;
    font-weight: normal;
}

p {
    white-space: pre-line;
    font-family: 'Grotesque Regular', Helvetica, Arial, sans-serif;
}

ul, ol {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

@media (hover: hover) {
    a:hover {
        text-decoration: none;
    }
}

input,
textarea {
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    box-sizing: border-box;
    font: inherit;
    height: auto;
    resize: none;
}

input,
textarea,
[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.ptr--ptr {
    box-shadow: none !important;
}

.ptr--text {
    display: none;
}

.pb300 {
    font-family: 'Grotesque Regular', Helvetica, Arial, sans-serif;
}

.pb500 {
    font-family: 'Grotesque Medium', Helvetica, Arial, sans-serif;
}

.pb700 {
    font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;
}
